/* eslint-disable */

<template>
  <div id="custom265860757000814592">
    <vue-sortable :options="options" @drag:start="startedDrag" @drag:stop="stoppedDrag" @sortable:sort="sort">
      <div class="vx-row" :class="{ 'cursor-drag': draggingCard === true }">
        <div class="vx-col lg:w-3/12 md:w-3/12 sm:w-5/5">
          <vx-card class="mb-base">
            <h3 class="font-bold mb-4 p-2 rounded" style="background-color: #0084ff">Drag and Drop</h3>
            <div class="flex justify-between my-2 officeBudgetBlock">
              <h4 class="self-center pr-4">Anti-Virus Server | $10,000</h4>
              <vue-draggable-container class="wbOfficeBudget">
                <h5 class="dragBlock officeBudgetDragBlock cursor-pointer">1</h5>
              </vue-draggable-container>
            </div>
            <div class="flex justify-between my-2 officeBudgetBlock">
              <h4 class="self-center pr-4">Firewall | $15,000</h4>
              <vue-draggable-container class="wbOfficeBudget">
                <h5 class="dragBlock officeBudgetDragBlock cursor-pointer">2</h5>
              </vue-draggable-container>
            </div>
            <div class="flex justify-between my-2 officeBudgetBlock">
              <h4 class="self-center pr-4">Packet Analyzer | $5,000</h4>
              <vue-draggable-container class="wbOfficeBudget">
                <h5 class="dragBlock officeBudgetDragBlock cursor-pointer">5</h5>
              </vue-draggable-container>
            </div>
            <div class="flex justify-between my-2 officeBudgetBlock">
              <h4 class="self-center pr-4">Packet Analyzer | $5,000</h4>
              <vue-draggable-container class="wbOfficeBudget">
                <h5 class="dragBlock officeBudgetDragBlock cursor-pointer">5</h5>
              </vue-draggable-container>
            </div>
            <div class="flex justify-between my-2 officeBudgetBlock">
              <h4 class="self-center pr-4">Packet Analyzer | $5,000</h4>
              <vue-draggable-container class="wbOfficeBudget">
                <h5 class="dragBlock officeBudgetDragBlock cursor-pointer">5</h5>
              </vue-draggable-container>
            </div>
            <div class="flex justify-between my-2 officeBudgetBlock">
              <h4 class="self-center pr-4">Patch Server | $15,000</h4>
              <vue-draggable-container class="wbOfficeBudget">
                <h5 class="dragBlock officeBudgetDragBlock cursor-pointer">6</h5>
              </vue-draggable-container>
            </div>
            <div class="flex justify-between my-2 officeBudgetBlock">
              <h4 class="self-center pr-4">Proxy Server | $20,000</h4>
              <vue-draggable-container class="wbOfficeBudget">
                <h5 class="dragBlock officeBudgetDragBlock cursor-pointer">7</h5>
              </vue-draggable-container>
            </div>
          </vx-card>
        </div>
        <div class="vx-col lg:w-9/12 md:w-9/12 sm:w-5/5">
          <vx-card class="mb-base">
            <div>
              <div class="vx-row">
                <div class="vx-col w-full">
                  <div class="grid grid-cols-8 gap-4">
                    <div class="rounded p-4 my-4 z-10">
                      <h4 class="font-semibold mb-2 text-center">Internet</h4>
                      <div class="flex justify-center">
                        <div class="office-budget-line-internet-down" />
                        <img
                          class="z-10"
                          src="https://cdn.discordapp.com/attachments/875074366348611635/875074393271840788/cloud-computing.png"
                          width="75"
                          height="75"
                        />
                      </div>
                    </div>
                    <div></div>
                    <div class="flex items-center p-4 my-4">
                      <div class="w-full z-10">
                        <vue-draggable-container class="dropzone dropzoneStandard answer-bg answerOfficeBudget" id="input0" />
                      </div>
                      <div class="office-budget-line-row-1" />
                    </div>
                    <div class="flex items-center p-4 my-4">
                      <div class="w-full z-10">
                        <vue-draggable-container class="dropzone dropzoneStandard answer-bg answerOfficeBudget" id="input1" />
                      </div>
                    </div>
                    <div class="rounded p-4 my-4 z-10">
                      <h4 class="font-semibold mb-2 text-center">Directory Services</h4>
                      <div class="flex justify-center">
                        <img
                          src="https://cdn.discordapp.com/attachments/875074366348611635/875075538035826688/cloud-server.png"
                          width="75"
                          class="z-10"
                          height="75"
                        />
                      </div>
                    </div>
                    <div class="rounded p-4 my-4 z-10">
                      <br />
                      <h4 class="font-semibold mb-2 text-center">Print</h4>
                      <div class="flex justify-center">
                        <img
                          src="https://cdn.discordapp.com/attachments/875074366348611635/875075538035826688/cloud-server.png"
                          width="75"
                          class="z-10"
                          height="75"
                        />
                        <div class="office-budget-line-print-down" />
                      </div>
                    </div>
                    <div class="rounded p-4 my-4 z-10">
                      <br />
                      <h4 class="font-semibold mb-2 text-center">File</h4>
                      <div class="flex justify-center">
                        <img
                          src="https://cdn.discordapp.com/attachments/875074366348611635/875075538035826688/cloud-server.png"
                          width="75"
                          class="z-10"
                          height="75"
                        />
                      </div>
                    </div>
                    <div class="rounded p-4 my-4 z-10">
                      <h4 class="font-semibold mb-2 text-center">Database Server</h4>
                      <div class="flex justify-center">
                        <img
                          src="https://cdn.discordapp.com/attachments/875074366348611635/875075538035826688/cloud-server.png"
                          width="75"
                          class="z-10"
                          height="75"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="vx-row">
                <div class="vx-col w-full">
                  <div class="grid grid-cols-8 gap-4">
                    <div class="rounded p-4 my-4 z-10">
                      <h4 class="font-semibold mb-2 text-center">Router</h4>
                      <div class="flex justify-center mt-4">
                        <img
                          src="https://cdn.discordapp.com/attachments/875074366348611635/875076376527532062/wireless-router.png"
                          width="75"
                          class="z-10"
                          height="75"
                        />
                      </div>
                    </div>
                    <div class="flex items-center p-4 my-4">
                      <div class="office-budget-line-row-2" />
                      <div class="w-full z-10">
                        <vue-draggable-container class="dropzone dropzoneStandard answer-bg answerOfficeBudget" id="input3" />
                      </div>
                    </div>
                    <div class="flex items-center p-4 my-4">
                      <div class="w-full z-10">
                        <vue-draggable-container class="dropzone dropzoneStandard answer-bg answerOfficeBudget" id="input4" />
                      </div>
                    </div>
                    <div class="flex items-center justify-center p-4 my-4">
                      <div class="office-budget-line-2-down" />
                      <div class="w-full z-10">
                        <vue-draggable-container class="dropzone dropzoneStandard answer-bg answerOfficeBudget" id="input5" />
                      </div>
                    </div>
                    <div class="rounded p-4 my-4 z-10">
                      <h4 class="font-semibold mb-2 text-center">Router</h4>
                      <div class="flex justify-center mt-4">
                        <img
                          src="https://cdn.discordapp.com/attachments/875074366348611635/875076376527532062/wireless-router.png"
                          width="75"
                          class="z-10"
                          height="75"
                        />
                      </div>
                    </div>
                    <div class="rounded p-4 my-4 z-10">
                      <h4 class="font-semibold mb-2 text-center">Switch</h4>
                      <div class="flex justify-center mt-4">
                        <img
                          src="https://rapidsimulations.com/simulations/4/images/music-player-p-500.png"
                          width="75"
                          class="z-10"
                          height="75"
                        />
                      </div>
                    </div>
                    <div></div>
                    <div></div>
                  </div>
                </div>
              </div>
              <div class="vx-row">
                <div class="vx-col w-full">
                  <div class="grid grid-cols-8 gap-4">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div class="rounded p-4 my-4 z-10">
                      <h4 class="font-semibold mb-2 text-center">Switch</h4>
                      <div class="flex justify-center mt-4">
                        <img
                          src="https://rapidsimulations.com/simulations/4/images/music-player-p-500.png"
                          width="75"
                          class="z-10"
                          height="75"
                        />
                      </div>
                    </div>
                    <div class="flex items-center justify-center p-4 my-4">
                      <div class="office-budget-line-3-input-down" />
                      <div class="w-full z-10">
                        <vue-draggable-container class="dropzone dropzoneStandard answer-bg answerOfficeBudget" id="input6" />
                      </div>
                    </div>
                    <div class="flex items-center justify-center p-4 my-4">
                      <div class="office-budget-line-row-3-input" />
                      <div class="w-full z-10">
                        <vue-draggable-container class="dropzone dropzoneStandard answer-bg answerOfficeBudget" id="input7" />
                      </div>
                    </div>
                    <div class="flex items-center justify-center p-4 my-4">
                      <div class="office-budget-line-3-input-down" />
                      <div class="w-full z-10">
                        <vue-draggable-container class="dropzone dropzoneStandard answer-bg answerOfficeBudget" id="input8" />
                      </div>
                    </div>
                    <div></div>
                  </div>
                </div>
              </div>
              <div class="vx-row">
                <div class="vx-col w-full">
                  <div class="grid grid-cols-8 gap-4">
                    <div></div>
                    <div class="flex items-center justify-center p-4 my-4">
                      <div class="office-budget-line-4-input-down" />
                      <div class="w-full z-10">
                        <vue-draggable-container class="dropzone dropzoneStandard answer-bg answerOfficeBudget" id="input9" />
                      </div>
                    </div>
                    <div class="flex items-center justify-center p-4 my-4">
                      <div class="office-budget-line-4-input-down" />
                      <div class="office-budget-line-row-3-input" />
                      <div class="w-full z-10">
                        <vue-draggable-container class="dropzone dropzoneStandard answer-bg answerOfficeBudget" id="input10" />
                      </div>
                    </div>
                    <div class="flex items-center justify-center p-4 my-4">
                      <div class="w-full z-10">
                        <vue-draggable-container class="dropzone dropzoneStandard answer-bg answerOfficeBudget" id="input11" />
                      </div>
                    </div>
                    <div class="rounded p-4 my-4 z-10">
                      <h4 class="font-semibold mb-2 text-center">Switch</h4>
                      <div class="flex justify-center mt-4">
                        <img
                          src="https://rapidsimulations.com/simulations/4/images/music-player-p-500.png"
                          width="75"
                          class="z-10"
                          height="75"
                        />
                      </div>
                    </div>
                    <div class="rounded p-4 my-4 z-10">
                      <h4 class="font-semibold mb-2 text-center">Switch</h4>
                      <div class="flex justify-center mt-4">
                        <img
                          src="https://rapidsimulations.com/simulations/4/images/music-player-p-500.png"
                          width="75"
                          class="z-10"
                          height="75"
                        />
                      </div>
                    </div>
                    <div class="rounded p-4 my-4 z-10">
                      <h4 class="font-semibold mb-2 text-center">Switch</h4>
                      <div class="flex justify-center mt-4">
                        <img
                          src="https://rapidsimulations.com/simulations/4/images/music-player-p-500.png"
                          width="75"
                          class="z-10"
                          height="75"
                        />
                      </div>
                    </div>
                    <div></div>
                  </div>
                </div>
              </div>
              <div class="vx-row">
                <div class="vx-col w-full">
                  <div class="grid grid-cols-8 gap-4">
                    <div></div>
                    <div class="rounded p-4 my-4 z-10">
                      <h4 class="font-semibold mb-2 text-center">DNS</h4>
                      <div class="flex justify-center mt-4">
                        <img
                          src="https://cdn.discordapp.com/attachments/875074366348611635/875075538035826688/cloud-server.png"
                          width="75"
                          class="z-10"
                          height="75"
                        />
                      </div>
                    </div>
                    <div class="rounded p-4 my-4 z-10">
                      <h4 class="font-semibold mb-2 text-center">DNS</h4>
                      <div class="flex justify-center mt-4">
                        <img
                          src="https://cdn.discordapp.com/attachments/875074366348611635/875075538035826688/cloud-server.png"
                          width="75"
                          class="z-10"
                          height="75"
                        />
                      </div>
                    </div>
                    <div class="rounded p-4 my-4 z-10">
                      <h4 class="font-semibold mb-2 text-center">DNS</h4>
                      <div class="flex justify-center mt-4">
                        <img
                          src="https://cdn.discordapp.com/attachments/875074366348611635/875075538035826688/cloud-server.png"
                          width="75"
                          class="z-10"
                          height="75"
                        />
                      </div>
                    </div>
                    <div class="rounded p-4 my-4 z-10">
                      <h4 class="font-semibold mb-2 text-center">Workstations</h4>
                      <div class="flex justify-center mt-4">
                        <img
                          src="https://cdn.discordapp.com/attachments/875074366348611635/875075852818329660/work-station.png"
                          width="75"
                          class="z-10"
                          height="75"
                        />
                      </div>
                    </div>
                    <div class="rounded p-4 my-4 z-10">
                      <h4 class="font-semibold mb-2 text-center">Workstations</h4>
                      <div class="flex justify-center mt-4">
                        <img
                          src="https://cdn.discordapp.com/attachments/875074366348611635/875075852818329660/work-station.png"
                          width="75"
                          class="z-10"
                          height="75"
                        />
                      </div>
                    </div>
                    <div class="rounded p-4 my-4 z-10">
                      <h4 class="font-semibold mb-2 text-center">Workstations</h4>
                      <div class="flex justify-center mt-4">
                        <img
                          src="https://cdn.discordapp.com/attachments/875074366348611635/875075852818329660/work-station.png"
                          width="75"
                          class="z-10"
                          height="75"
                        />
                      </div>
                    </div>
                    <div></div>
                  </div>
                </div>
              </div>
            </div>
          </vx-card>
        </div>
      </div>
    </vue-sortable>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import Prism from 'vue-prism-component';
import vSelect from 'vue-select';

export default {
  data() {
    return {
      dataLocal: JSON.parse(JSON.stringify(this.data)),
      draggingCard: false,

      options: {
        draggable: '.dragBlock',
        dropzone: '.dropzoneStandard',
      },
    };
  },
  methods: {
    startedDrag(x) {
      x.data.source.className += ' currently-dragging-standard';
      this.draggingCard = true;
    },
    stoppedDrag() {
      this.draggingCard = false;
    },
    sort(x) {
      const overContainer = x.data.dragEvent.data.overContainer;
      const origSourceContainer = x.data.dragEvent.data.originalSource;
      if (overContainer.classList.contains('wbOfficeBudget')) {
        if (overContainer.children.length > 0) {
          return x.cancel();
        }
      }
      if (overContainer && overContainer.classList.contains('dropzone')) {
        const id = origSourceContainer.id;
        document.getElementById(`${id}-cloned`).style.display = 'inline-flex';
        document.getElementById(id).classList.remove('dragBlock');
        document.getElementById(id).classList.add('stillBlockDataClassification');
        return;
      }
      document.getElementById(`${origSourceContainer.id}-cloned`).outerHTML = '';
    },
    markSimulation() {
      let totalScore = 0;

      if (document.getElementById('input0').textContent === '1') {
        document.getElementById('input0').style.borderColor = '#28C76F';
        document.getElementById('input0').style.backgroundColor = '#28C76F';
        totalScore++;
      } else if (document.getElementById('input0').textContent !== '') {
        document.getElementById('input0').style.borderColor = '#fd4445';
        document.getElementById('input0').style.backgroundColor = '#fd4445';
      }

      if (document.getElementById('input1').textContent === '6') {
        document.getElementById('input1').style.borderColor = '#28C76F';
        document.getElementById('input1').style.backgroundColor = '#28C76F';
        totalScore++;
      } else if (document.getElementById('input1').textContent !== '') {
        document.getElementById('input1').style.borderColor = '#fd4445';
        document.getElementById('input1').style.backgroundColor = '#fd4445';
      }

      if (document.getElementById('input3').textContent === '2') {
        document.getElementById('input3').style.borderColor = '#28C76F';
        document.getElementById('input3').style.backgroundColor = '#28C76F';
        totalScore++;
      } else if (document.getElementById('input3').textContent !== '') {
        document.getElementById('input3').style.borderColor = '#fd4445';
        document.getElementById('input3').style.backgroundColor = '#fd4445';
      }

      if (document.getElementById('input4').textContent === '7') {
        document.getElementById('input4').style.borderColor = '#28C76F';
        document.getElementById('input4').style.backgroundColor = '#28C76F';
        totalScore++;
      } else if (document.getElementById('input4').textContent !== '') {
        document.getElementById('input4').style.borderColor = '#fd4445';
        document.getElementById('input4').style.backgroundColor = '#fd4445';
      }

      if (document.getElementById('input6').textContent === '5') {
        document.getElementById('input6').style.borderColor = '#28C76F';
        document.getElementById('input6').style.backgroundColor = '#28C76F';
        totalScore++;
      } else if (document.getElementById('input6').textContent !== '') {
        document.getElementById('input6').style.borderColor = '#fd4445';
        document.getElementById('input6').style.backgroundColor = '#fd4445';
      }

      if (document.getElementById('input7').textContent === '5') {
        document.getElementById('input7').style.borderColor = '#28C76F';
        document.getElementById('input7').style.backgroundColor = '#28C76F';
        totalScore++;
      } else if (document.getElementById('input7').textContent !== '') {
        document.getElementById('input7').style.borderColor = '#fd4445';
        document.getElementById('input7').style.backgroundColor = '#fd4445';
      }
      if (document.getElementById('input8').textContent === '5') {
        document.getElementById('input8').style.borderColor = '#28C76F';
        document.getElementById('input8').style.backgroundColor = '#28C76F';
        totalScore++;
      } else if (document.getElementById('input8').textContent !== '') {
        document.getElementById('input8').style.borderColor = '#fd4445';
        document.getElementById('input8').style.backgroundColor = '#fd4445';
      }

      if (totalScore === this.dataLocal.simulation.totalScore) {
        return this.$emit('simulation_full_correct', totalScore);
      }

      this.$emit('simulation_getting_there', totalScore);
    },
    /* click_remove (x) {
      const target = x.target
      if (target.classList.contains('stillBlockDataClassification')) {
        target.outerHTML = ''
      }
    } */
  },
  components: {
    draggable,
    Prism,
    vSelect,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>
<style lang="scss">
#custom265860757000814592 {
  .office-budget-line-row-1 {
    position: absolute;
    width: 35.5vw;
    height: 6px;
    background-color: #0285e4;
  }
  .office-budget-line-row-2 {
    position: absolute;
    width: 33.1vw;
    margin-left: -5vw;
    height: 6px;
    background-color: #0285e4;
  }
  .office-budget-line-row-3-input {
    position: absolute;
    width: 13.1vw;
    height: 6px;
    background-color: #0285e4;
  }
  .office-budget-line-print-down {
    position: absolute;
    left: auto;
    right: auto;
    width: 6px;
    height: 48.5rem;
    margin-top: 3.5rem;
    background-color: #0285e4;
  }
  .office-budget-line-2-down {
    position: absolute;
    left: auto;
    right: auto;
    width: 6px;
    height: 36.5rem;
    margin-top: 35.5rem;
    background-color: #0285e4;
  }
  .office-budget-line-3-input-down {
    position: absolute;
    width: 6px;
    height: 25.6rem;
    margin-top: 24.5rem;
    background-color: #0285e4;
  }
  .office-budget-line-4-input-down {
    position: absolute;
    width: 6px;
    height: 13.6rem;
    margin-top: 13.5rem;
    background-color: #0285e4;
  }
  .office-budget-line-internet-down {
    position: absolute;
    width: 6px;
    height: 12rem;
    margin-top: 2.9rem;
    background-color: #0285e4;
  }
  .officeBudgetDragBlock {
    min-width: 50px !important;
    background-color: #ffd900;
    color: #000000 !important;
    font-weight: 800 !important;
  }

  .officeBudgetBlock {
    border-color: #ffffff;
    border-style: solid;
    border-width: 2px 0 0 0;
    padding: 7px 0 7px 0;
  }

  .answerOfficeBudget {
    display: flex;
    min-height: 90.5px;
    justify-content: center;
    align-items: center;
  }

  .wbOfficeBudget {
    min-width: 65px;
    min-height: 55px;
    max-height: 65px;
    border: 1px solid rgba(255, 255, 255, 0.75);
    justify-content: center;
    display: flex;
  }

  .stillBlockDataClassification {
    padding: 12px;
    word-break: break-all;
    background-color: rgba(var(--vs-primary), 1);
    display: inline-flex;
    margin: 5px;
    border-radius: 5px;
    font-size: 1.4rem;
    text-align: center;
    justify-content: center;
    align-items: center;
    z-index: 999999999999 !important;
    border: 0.18rem solid transparent;
    font-weight: 800 !important;
    cursor: url('~@/assets/images/cursor/trash-cursor.png'), auto !important;
    background-color: #ffd900;
    color: #000000 !important;
  }
}
</style>
